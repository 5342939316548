import React, { useContext } from 'react';
import FunnelContext from '../../../context/FunnelContext';
import { breeds, months, breeds2 } from './OptionValues';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const AnswerSelect = props => {
  const { answers, setAnswers } = useContext(FunnelContext);

  const {
    type,
    options,
    range_start = 0,
    range_end = 10,
    placeholder,
    showMenuOnFocus,
    filterByPetType = 'dog'
  } = props;

  const handleChange = value => {
    if (value) {
      const newAnswers = Object.assign(answers, {
        [props.name]: value.value
      });
      return setAnswers(newAnswers);
    }
  };

  const canCreate = () => {
    switch (type) {
      case 'breeds':
        return true;
      default:
        return false;
    }
  };

  const OptionsData = () => {
    switch (type) {
      case 'breeds':
        return breeds;
      case 'breeds2':
        return breeds2.filter(b => b.petType === filterByPetType);
      case 'months':
        return months;
      case 'range':
        const ranges = [];
        for (let i = range_start; i <= range_end; i++) {
          ranges.push({ value: i, label: i });
        }
        return ranges;
      default:
      case 'normal':
        return options;
    }
  };

  const Container = () => {
    if (canCreate()) {
      return (
        <CreatableSelect
          openMenuOnFocus={showMenuOnFocus ? true : false}
          openMenuOnClick={showMenuOnFocus ? true : false}
          placeholder={placeholder || 'Select...'}
          isClearable={true}
          isSearchable={true}
          options={OptionsData()}
          {...props.attributes}
          onChange={handleChange}
        />
      );
    }

    return (
      <Select
        openMenuOnFocus={showMenuOnFocus ? true : false}
        openMenuOnClick={showMenuOnFocus ? true : false}
        placeholder={placeholder || 'Select...'}
        isClearable={true}
        isSearchable={true}
        options={OptionsData()}
        {...props.attributes}
        onChange={handleChange}
      />
    );
  };

  return <Container />;
};

export default AnswerSelect;
